<template>
    <div class="score-container">
        <div class="meta-title">Score</div>
        <div class="score--positive-score">
            {{$store.state.userScore}}
            <!-- <div
            class="score--negative-score"
            v-if="this.dataWrongAnswers < 0"
            >{{dataWrongAnswers}}</div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "appUserScore", 
}
</script>
<style lang="scss" scoped>
.score--positive-score {
    padding-left: 5px;
    // border: thin red solid;
}
</style>
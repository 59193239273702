<template>
  <header class="top-header">
    <h1>{{ msg }}</h1>
  </header>
</template>

<script>
export default {
  name: "appHeader",
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/global.scss";
@import "../assets/styles/mediaqueries.scss";

h1 {
  font-size: 22px;
  padding: 11px 20px;
  font-family: "Avenir", "Roboto", "Proxima Nova", "Helvetica";
  margin-top: 0;
  margin-bottom: 10px;
  text-align: left;
}

.top-header {
  /* background: whitesmoke; */
  height: 54px;
  background-image: linear-gradient(
    to right,
    orange,
    orangered 20%,
    skyblue 36%,
    deepskyblue 55%,
    hotpink 68%,
    #98d698 89%
  );
  background-size: 100% 2px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
</style>

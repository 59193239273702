<template>
    <div class="progess_bar" :style="{ width: barWidth + '%' }">{{progress}}</div>
</template>
<script>


export default {
    name:"appProgressBar", 

    data: function() {
        return {
            barWidth: 0,
            progress: '',
        }

    },    

    methods:{
        progressBar: function(){
            this.barWidth++
        }
    }

}
</script>
<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/global.scss";

    .progess_bar{
        position: absolute;
        height:6px; 
        background: #00ff2e;
        top:76px;
    }

</style>
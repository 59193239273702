export const RANDOM_INT_RANGE = {
    range : 10
}

export const DISPLAY_NAME_CHAR_MAX = {
    max: 8
}

export const QUIZ_INPUT_MAX = {
    max : 3
}
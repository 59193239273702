<template>
    <div class="level-container">
        <div class="meta-title">level</div>
        <div class="meta-title level-int">{{dataLevel}}</div>
    </div>
    
</template>

<script>

export default {

    // TODO: use vuex mapState and mapAction

    name: "appUserLevel", 

    data: () => {
        return {
            dataLevel: '',
        }
    },

    mounted(){
        this.dataLevel = this.$store.getters.getUserLevelData
    },

    methods: {
        setUserLevel: function() {
        //TODO : stop from firing when user is typing!

        if (this.$store.getters.getUserScore === 1) {
            //condition value is Zero based
            // this.character = "pikachu";
            // this.dataLevel = "1";
            this.$store.commit("setUserLevelData", "1"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)

        } else if (this.$store.getters.getUserScore === 3) {
            // this.character = "raichu";
            // this.dataLevel = "2";
            this.$store.commit("setUserLevelData", "2"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)        
        } else if (this.$store.getters.getUserScore  === 5) {
            // this.dataLevel = "3";
            this.$store.commit("setUserLevelData", "3"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)            
        } else if (this.$store.getters.getUserScore  === 7) {
            // this.character = "eevee";
            // this.dataLevel = "4";
            this.$store.commit("setUserLevelData", "4"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)               
        } else if (this.$store.getters.getUserScore  === 10) {
            // this.character = "meowth";
            // this.dataLevel = "5";
            this.$store.commit("setUserLevelData", "5"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)  
        } else if (this.$store.getters.getUserScore  === 13) {
            // this.character = "squirtle";
            // this.dataLevel = "6";
            this.$store.commit("setUserLevelData", "6"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)  

        } else if (this.$store.getters.getUserScore  === 15) {
            // this.character = "lapras";
            // this.dataLevel = "7";
            this.$store.commit("setUserLevelData", "7"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)          
        } else if (this.$store.getters.getUserScore  === 20) {
            // this.character = "gyarados";
            // this.dataLevel = "8";
            this.$store.commit("setUserLevelData", "8"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)               
        } else if (this.$store.getters.getUserScore  === 25) {
            // this.character = "rhyhorn";
            // this.dataLevel = "10";
            this.$store.commit("setUserLevelData", "10"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)               
        } else if (this.$store.getters.getUserScore  === 30) {
            // this.character = "charizard";
            // this.dataLevel = "15";
            this.$store.commit("setUserLevelData", "15"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)             
        } else if (this.$store.getters.getUserScore  === 40) {
            // this.character = "snorlax";
            // this.dataLevel = "20";
            this.$store.commit("setUserLevelData", "20"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)          
        } else if (this.$store.getters.getUserScore  === 50) {
            // this.character = "dragonite";
            // this.dataLevel = "25";
            this.$store.commit("setUserLevelData", "25"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)          
        } else if (this.$store.getters.getUserScore  === 65) {
            // this.character = "kyogre";
            // this.dataLevel = "30";
            this.$store.commit("setUserLevelData", "30"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)          
        } else if (this.$store.getters.getUserScore  === 70) {
            // this.character = "rayquaza";
            // this.dataLevel = "35";
            this.$store.commit("setUserLevelData", "35"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)           
        } else if (this.$store.getters.getUserScore  === 75) {
            // this.character = "palkia";
            // this.dataLevel = "40";
            this.$store.commit("setUserLevelData", "40"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)           
        } else if (this.$store.getters.getUserScore  === 80) {
            // this.character = "giratina";
            // this.dataLevel = "45";
            this.$store.commit("setUserLevelData", "45"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)           
        } else if (this.$store.getters.getUserScore  === 90) {
            // this.character = "hoopa";
            // this.dataLevel = "46";
            this.$store.commit("setUserLevelData", "46"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)                
        } else if (this.$store.getters.getUserScore  === 96) {
            // this.character = "dialga";
            // this.dataLevel = "49";
            this.$store.commit("setUserLevelData", "49"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)            
        } else if (this.$store.getters.getUserScore  === 100) {
            this.dataLevel = "50";
            this.$store.commit("setUserLevelData", "50"); //set the state in global state 
            this.dataLevel = this.$store.getters.getUserLevelData; // get the state form global state (seems long winded !)           
            // this.character = "arceus";
        } else if (this.$store.getters.getUserScore  === 101) {
            console.log("ENDGAME");
            /*
            BUG: Runs into recursion if the score is not > 100 
            it continues to reCalc for a new pair of numbers to solve!
            */
           
           // this.endGame();
        }

        // let images = require.context("../assets/", false, /\.png$/);
        // return images("./" + this.character + ".png");
        }
    }

};

</script>

<style lang="scss" scoped>
.level-int {
    padding-left: 5px;
}
</style>
<template>
        <div class="ingame-grid-table-conatiner">
          <div class="multiplier-icon item">x</div>
          <div class="x-axis item">1</div>
          <div class="x-axis item">2</div>
          <div class="x-axis item">3</div>
          <div class="x-axis item">4</div>
          <div class="x-axis item">5</div>
          <div class="x-axis item">6</div>
          <div class="x-axis item">7</div>
          <div class="x-axis item">8</div>
          <div class="x-axis item">9</div>
          <div class="x-axis item">10</div>

          <div class="y-axis item">1</div>

          <div class="intData item row-1 col-1"></div>
          <div class="intData item row-1 col-2"></div>
          <div class="intData item row-1 col-3"></div>
          <div class="intData item row-1 col-4"></div>
          <div class="intData item row-1 col-5"></div>
          <div class="intData item row-1 col-6"></div>
          <div class="intData item row-1 col-7"></div>
          <div class="intData item row-1 col-8"></div>
          <div class="intData item row-1 col-9"></div>
          <div class="intData item row-1 col-10"></div>

          <div class="y-axis item">2</div>

          <div class="intData item row-2 col-1"></div>
          <div class="intData item row-2 col-2"></div>
          <div class="intData item row-2 col-3"></div>
          <div class="intData item row-2 col-4"></div>
          <div class="intData item row-2 col-5"></div>
          <div class="intData item row-2 col-6"></div>
          <div class="intData item row-2 col-7"></div>
          <div class="intData item row-2 col-8"></div>
          <div class="intData item row-2 col-9"></div>
          <div class="intData item row-2 col-10"></div>

          <div class="y-axis item">3</div>

          <div class="intData item row-3 col-1"></div>
          <div class="intData item row-3 col-2"></div>
          <div class="intData item row-3 col-3"></div>
          <div class="intData item row-3 col-4"></div>
          <div class="intData item row-3 col-5"></div>
          <div class="intData item row-3 col-6"></div>
          <div class="intData item row-3 col-7"></div>
          <div class="intData item row-3 col-8"></div>
          <div class="intData item row-3 col-9"></div>
          <div class="intData item row-3 col-10"></div>

          <div class="y-axis item">4</div>

          <div class="intData item row-4 col-1"></div>
          <div class="intData item row-4 col-2"></div>
          <div class="intData item row-4 col-3"></div>
          <div class="intData item row-4 col-4"></div>
          <div class="intData item row-4 col-5"></div>
          <div class="intData item row-4 col-6"></div>
          <div class="intData item row-4 col-7"></div>
          <div class="intData item row-4 col-8"></div>
          <div class="intData item row-4 col-9"></div>
          <div class="intData item row-4 col-10"></div>

          <div class="y-axis item">5</div>

          <div class="intData item row-5 col-1"></div>
          <div class="intData item row-5 col-2"></div>
          <div class="intData item row-5 col-3"></div>
          <div class="intData item row-5 col-4"></div>
          <div class="intData item row-5 col-5"></div>
          <div class="intData item row-5 col-6"></div>
          <div class="intData item row-5 col-7"></div>
          <div class="intData item row-5 col-8"></div>
          <div class="intData item row-5 col-9"></div>
          <div class="intData item row-5 col-10"></div>

          <div class="y-axis item">6</div>

          <div class="intData item row-6 col-1"></div>
          <div class="intData item row-6 col-2"></div>
          <div class="intData item row-6 col-3"></div>
          <div class="intData item row-6 col-4"></div>
          <div class="intData item row-6 col-5"></div>
          <div class="intData item row-6 col-6"></div>
          <div class="intData item row-6 col-7"></div>
          <div class="intData item row-6 col-8"></div>
          <div class="intData item row-6 col-9"></div>
          <div class="intData item row-6 col-10"></div>

          <div class="y-axis item">7</div>

          <div class="intData item row-7 col-1"></div>
          <div class="intData item row-7 col-2"></div>
          <div class="intData item row-7 col-3"></div>
          <div class="intData item row-7 col-4"></div>
          <div class="intData item row-7 col-5"></div>
          <div class="intData item row-7 col-6"></div>
          <div class="intData item row-7 col-7"></div>
          <div class="intData item row-7 col-8"></div>
          <div class="intData item row-7 col-9"></div>
          <div class="intData item row-7 col-10"></div>

          <div class="y-axis item">8</div>

          <div class="intData item row-8 col-1"></div>
          <div class="intData item row-8 col-2"></div>
          <div class="intData item row-8 col-3"></div>
          <div class="intData item row-8 col-4"></div>
          <div class="intData item row-8 col-5"></div>
          <div class="intData item row-8 col-6"></div>
          <div class="intData item row-8 col-7"></div>
          <div class="intData item row-8 col-8"></div>
          <div class="intData item row-8 col-9"></div>
          <div class="intData item row-8 col-10"></div>

          <div class="y-axis item">9</div>

          <div class="intData item row-9 col-1"></div>
          <div class="intData item row-9 col-2"></div>
          <div class="intData item row-9 col-3"></div>
          <div class="intData item row-9 col-4"></div>
          <div class="intData item row-9 col-5"></div>
          <div class="intData item row-9 col-6"></div>
          <div class="intData item row-9 col-7"></div>
          <div class="intData item row-9 col-8"></div>
          <div class="intData item row-9 col-9"></div>
          <div class="intData item row-9 col-10"></div>

          <div class="y-axis item">10</div>

          <div class="intData item row-10 col-1"></div>
          <div class="intData item row-10 col-2"></div>
          <div class="intData item row-10 col-3"></div>
          <div class="intData item row-10 col-4"></div>
          <div class="intData item row-10 col-5"></div>
          <div class="intData item row-10 col-6"></div>
          <div class="intData item row-10 col-7"></div>
          <div class="intData item row-10 col-8"></div>
          <div class="intData item row-10 col-9"></div>
          <div class="intData item row-10 col-10"></div>

        </div>

</template>

<script>
export default {
  name: "appTableHTML"
};
</script>

<style lang="scss" scoped>

@import "../assets/styles/_variables.scss";


.row-10.int-table-data-correct:first-child:nth-last-child(1),
.row-10.int-table-data-correct:first-child:nth-last-child(1) ~ .row-10.int-table-data-correct {
  // background: linear-gradient(90deg, #73ff00 0%, #22C1C3 100%);
  background: red!important;
}

  .ingame-grid-table-conatiner{
    .row-10.int-table-data-correct {
      // background:red
    }
  }


      /* START ingame grid Table styles */
      .ingame-grid-table-conatiner {
        display: grid;
        grid-gap: 5px;
        // border: 2px solid $yellow-color;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;

        > * {
          border: 1px solid #646464;
          border-width: 0 1px 1px 0;
          background-color: $dark-shaded;
          display: flex; /* flex styling to center content in divs */
          align-items: center;
          justify-content: center;
        }

        .int-table-data-correct {
          font-weight: bold;
          background: $gradient5;

          animation: flip-diagonal-2-br 0.3s
            cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        }

        @keyframes flip-diagonal-2-br {
          0% {
            opacity: 0;
            transform: rotate3d(-1, 1, 0, 120deg);
          }
          100% {
            opacity: 1;
            transform: rotate3d(-1, 1, 0, 0deg);
          }
        }

        .x-axis,
        .y-axis {
          // background-color: #fc5721;
          font-weight: bold;
          background-color: cornflowerblue;
        }
        .multiplier-icon {
          font-weight: bold;
          background-color: cornflowerblue;
        }

        .intData {
          // visibility: hidden;
          // color: #ffffff;
          // background-color: cornflowerblue;
        }
      }
      /* END ingame grid Table styles */

</style>
<template>
        <div class="timer-container">
          <div id="timer">
            <div class="time">
            {{($store.state.time.h)?($store.state.time.h)+':':''}}
            {{($store.state.time.m > 9)?$store.state.time.m:'0'+$store.state.time.m}}:{{($store.state.time.s > 9)?$store.state.time.s:'0'+$store.state.time.s}}.{{$store.state.time.ms}}
            </div>
          </div>
        </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'; 

export default {
    name: "appTimer", 
    methods: {
    ...mapActions(['setTimerStart']),    
    ...mapMutations(['setTimer']),
            
    startTimer: function() {
        this.setTimerStart(true);
        console.log("starting timer ");
        setInterval( () => {
            this.setTimer();
        }, 100);
    }

    }
}
</script>
<style lang="scss" scoped>

 @import "../assets/styles/_variables.scss";
 
    .timer-container {
      margin-top: 15px;
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
      grid-gap: 10px;
      &::before,
      &::after {
        display: block;
        content: "";
        height: 10px;
        background: linear-gradient(left, #ffffff00 18%, $elements 100%);
      }
      &::after {
        background: linear-gradient(right, #ffffff00 18%, $elements 100%);
        // --direction: right;
      }

      .time {
        font-size: 1.7em;
        line-height: 1.2;
        font-weight: bold;
        color: #feffdd;
      }
    }
</style>
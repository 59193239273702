<template>
  <div id="app">
    <appHeader msg="Speedy Tables"/>
    <appMultiply message ref="appMultiply"/>
     <!-- <appDevMode message ref="devmode"/> -->

    <appFooter />
   
  </div>
</template>

<script>


import appHeader from "./components/appHeader.vue";
import appMultiply from "./components/appMultiply.vue";
import appFooter from "./components/appFooter.vue";
// import appLeaderBoard from "./components/appLeaderBoard.vue"
// import appDevMode from "./components/appDevMode.vue";


export default {
  name: "app",

  components: {
    appHeader,
    appMultiply,
    appFooter,
    // appLeaderBoard
    // appDevMode

  }
};
</script>

<style>
* {
  box-sizing: border-box;
}

body,
p {
  margin: 0;
}
body {
  counter-reset: section;
  background-image: url("./assets/bgpattern.png");
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #c7c7c7; */
  color: #1d1c1c;
  /* margin-top: 60px; */
}
</style>

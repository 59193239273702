<template>
  <div class="app-multiply-container">
   
    <div class="app-block">
     
      <div class="app-panel">
        
        <appTimer ref="appTimer"/>
        <appProgressBar ref="appProgressBar" v-if="$store.state.gridComplete === false"/>
        <div class="meta-container">
          <appUserScore />
          <appUserLevel message ref="userLevel"/>
        </div>
       
        <div class="result-container" v-if="$store.state.gridComplete === false">{{result}}</div>
<appTableHTML message/> 
        <transition name="fade">
          <div class="quiz-container" v-if="$store.state.quizPanel">
                
            <div class="quiz-int-container">
           
              <span class="quiz-content--int" v-html="dataRandomInt1"></span>
              <span class="quiz-content">x</span>
              <span class="quiz-content--int" v-html="dataRandomInt2"></span>
              <span class="quiz-content">=</span>
            </div>
            <div>
              <input
                @keyup.enter="checkAnswer"
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                min="0"
                :maxlength="dataQuizInputMax"
                v-model="userInput"
                placeholder="enter answer"
              />
            </div>
             
            <div>
              <button class="pure-material-button-contained" @click="checkAnswer">Go!</button>
            </div>
          </div>
        </transition>
        <div class="start-button" v-if="$store.state.startButton">
          <button class="pure-material-button-contained" @click="startGame">Play</button>
        </div>

        <div
          class="ingame-quiz-message-container"
          v-if="$store.state.gridComplete === false"
        >{{$store.state.dataInGameMsg}}</div>
        
        
   
        <!-- <appGridCompleteForm v-if="$store.state.gridComplete === true" /> -->
        <div class="grid-complete-container" v-if="$store.state.gridComplete === true">
          <div
            class="ingame-message ingame-message-complete"
          >Well done! save your score with your name, you will appear on the score board if you are in the top 10
          </div>
          
          <div v-if="$store.state.errors.length">
            <strong v-for="error in $store.state.errors" :key="error.id">{{ error }}</strong>
          </div>

          <input
            @keyup.enter="checkResultSubmitForm"
            type="text"
            id="playerName"
            :maxlength="dataPlayNameMaxCharacter"
            placeholder="Enter name"
            v-model="dataResults.playerName"
          />
          <button
            class="pure-material-button-contained"
            @click.prevent="checkResultSubmitForm"
            type="submit"
          >Submit</button>
        </div>
       
        <div class="results-send-complete-container" v-if="resultsSendComplete">
          <div class="ingame-message ingame-message-thankyou">Thanks for playing</div>
            <appLeaderBoard v-if="$store.state.dataLeaderBoardResults" ref="appLeaderBoard"/>
        </div>
        <!-- <p
          class="visible-none"
          v-html="multiplyMachineAnswer"
        >This has to be in the DOM for reasons unknown</p> -->
      </div>
      
    </div>
     <!-- <appDevMode />   -->
         
  </div>
  
</template>

<script>
import db from "../store/firebase"
import appTableHTML from "./appTableHTML.vue";
import appUserLevel from "./appUserLevel.vue";
import appTimer from "./appTimer.vue";
import appUserScore from "./appUserScore.vue"; 
// import appDevMode from "./appDevMode.vue";
import appLeaderBoard from "./appLeaderBoard.vue";
import appProgressBar from "./appProgressBar.vue";

// import appGridCompleteForm from "./appGridCompleteForm";
import {RANDOM_INT_RANGE, DISPLAY_NAME_CHAR_MAX, QUIZ_INPUT_MAX} from '../Constants';

import { mapState, mapActions, mapMutations } from 'vuex'; 

export default {
  name: "appMultiply",

  components: {
    appTableHTML,
    appUserLevel,
    appTimer,
    appUserScore,
    // appDevMode,
    appLeaderBoard,
    appProgressBar

    // appGridCompleteForm
  },

  data: function() {
    return {
      userInput: "",
      character: "ash", //pikachu //raichu
      dataQuizInputMax: QUIZ_INPUT_MAX.max,
      result: "",
      dataRange: RANDOM_INT_RANGE.range, // value will be int x int
      dataWrongAnswers: 0,
      resultsSendComplete: false,
      dataRandomInt1: "",
      dataRandomInt2: "",
      dataMachineCalcAnswer: "",
      numberStoreUpdate: 0,
      dataSendResultsCompleted: null,
      dataPlayNameMaxCharacter: DISPLAY_NAME_CHAR_MAX.max,
      dataResults: {
        playerName: null,
        score: null,
        wrongAnswers: null,
        character: null,
        time: null,
        level: null
        // training: [],
        // comment: ''
      },

    };
  },

  mounted(){
      // this.dataInGameMsg = this.$store.getters.getCurrentInGameMsg
      this.score = this.$store.getters.getUserScore
  },

  computed: {
    ...mapState(['dataInGameMsg'])
  }, 

  methods: {
    ...mapActions([
      'setTimerStart', 
      'setTimeOn', 
      'setStartButton',
      'setQuizPanel',
      'setUserScore',
      'setGridComplete',
      'setCurrentInGameMsg',
      'setLeaderBoardResults', 
      'setProgressBar'
      ]),
    ...mapMutations([
      'setTimer',
      'setLeaderBoardResults',
      'setError',
      'setNumberStore',
      'setNumberStoreUpdate'
      ]),

    generateRandomInt: function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },

    startGame: function() {
      this.setStartButton(false);
      this.setQuizPanel(true);
      this.setProgressBar(true);
      this.$refs.appTimer.startTimer();
      this.randomInt1();
      this.randomInt2();
    },

    checkAnswer: function() {

      if (this.dataRandomInt1 * this.dataRandomInt2 === parseInt(this.userInput)) {
        this.result = "Correct ✅ 🎉";
        this.userInput = "";
        this.setCurrentInGameMsg(""); //set the state in global state 
        
        
        this.score = this.setUserScore(this.$store.getters.getUserScore +1);
        this.score = this.$store.getters.getUserScore;

        this.updateGrid("correct");
        this.reCalc("correct_answer");

        this.$refs.userLevel.setUserLevel();
        this.$refs.appProgressBar.progressBar();
        this.focus();

      } else if (this.userInput === "") {
        this.setCurrentInGameMsg("Answer cannot be empty"); //set the state in global state 
        this.focus();
      } else {
        this.result = ` ${this.userInput} is wrong ❌ 👀 `;
        this.userInput = "";
        this.setCurrentInGameMsg("");
        // this.score -= this.score-1;
        // this.dataWrongAnswers -= wrongAnswers--;
        this.score = this.setUserScore(this.$store.getters.getUserScore -1);
        this.score = this.$store.getters.getUserScore;
        this.focus();
      }
    },

    reCalc: function(checkAnswerResultString) {
      /*
        BUG: called from showAnswer function will update (delete) from numberStore 
        if this function is called from show answer the negative score should be tracked
        the negative and positive score should be maintained in data and updated in the checkNumberStore function  
        the final score should be calculated using the negative and positive score 
      */

      setTimeout(() => {
        this.result = "";
      }, 1500);

      this.userInput = "";
      this.dataRandomInt1 = this.generateRandomInt(this.dataRange) + 1;
      this.dataRandomInt2 = this.generateRandomInt(this.dataRange) + 1;

      //ok to keep in local state at this point 
      this.numberStoreUpdate = this.dataRandomInt1 + "" + this.dataRandomInt2;
      this.numberStoreUpdate = parseInt(this.numberStoreUpdate);

      // pass to vuex state 
      this.setNumberStoreUpdate(this.numberStoreUpdate);

      // console.log(
      //   "numberStore[numberStoreUpdate] status -->  ",
      //   this.numberStore[this.numberStoreUpdate]
      // );

      this.dataMachineCalcAnswer = this.dataRandomInt1 * this.dataRandomInt2;

      this.checkNumberStore(checkAnswerResultString);
    },

    checkNumberStore: function() {
      // console.log(
      //   "SCORE From checkNumberStore ",
      //   this.numberStore[this.numberStoreUpdate] &&
      //     checkAnswerResultString === "correct_answer"
      // );

      if (
        Object.keys(this.$store.state.numberStore).length !== 0 &&
        this.$store.state.numberStore[this.numberStoreUpdate]
      ) {
        console.log(
          // this.$store.state.numberStore[this.numberStoreUpdate],
          "DEFINED Found & Deleted!"
        );

        // delete this.numberStore[this.numberStoreUpdate];

        this.setNumberStore(this.numberStoreUpdate);

        console.log("number store update >>", Object.keys(this.$store.state.numberStore).length)

      } else if (Object.keys(this.$store.state.numberStore).length === 0) {
        this.endGame();
      } else {
        this.reCalc();
      }
    },

    updateGrid: function(checkAnswerString) {
      let gridRow = ".row-";
      let gridCol = ".col-";
      gridRow += this.dataRandomInt1;
      gridCol += this.dataRandomInt2;
      let gridCellUpdate = document.querySelectorAll(gridRow + gridCol);

      if (checkAnswerString === "correct") {
        for (let i = 0; i < gridCellUpdate.length; i++) {
          gridCellUpdate[i].style.visibility = "visible";
          gridCellUpdate[i].className += " int-table-data-correct";
          gridCellUpdate[i].innerHTML = this.dataRandomInt1 * this.dataRandomInt2;
        }
      }


    },

    endGame: function() {
      console.log("END GAME!", this.$store.state.numberStore);
      // debugger;
      this.setTimeOn(false);
      this.setQuizPanel(false);
      this.setGridComplete(true);
      this.setProgressBar(false);
      // window.location.reload();
      //Show new screen
      // Final score
    },

    submitUserResults: function() {
      this.dataResults.score = this.$store.getters.getUserScore;
      this.dataResults.character = this.character;
      this.dataResults.wrongAnswers = this.dataWrongAnswers;
      this.dataResults.time = this.$store.state.time;
      this.dataResults.level = this.$store.state.userLevelData;

      db.collection("results")
        .add(this.dataResults)
        .then(() => {
          this.dataSendResultsCompleted = "success";
          this.resultsSendComplete = true;

          this.resetResultsData();
          this.leaderBoard();
          //this.$refs.appLeaderBoard.leaderBoard();
        })
        .catch(() => {
          this.completed = "failure";
          this.resetResultsData();
        });
    },

    resetResultsData: function() {
      this.dataResults = {};
    },

    leaderBoard: function() {
      console.log("LeaderBoard function ");

      let leaderBoardRef = db.collection("results");
      leaderBoardRef
        // .where("character", "==", "pikachu")
        .orderBy("score", "desc")
        .orderBy("time")
        .limit(10)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            console.log(doc.data());
            this.setLeaderBoardResults(doc.data());
          });
        })
        // .then(this.leaderBoardParse(), this.failureCallback())
        .catch(err => {
          console.log("Error getting documents", err);
        });

      // stuff.docs.map(doc => doc.data());
    },

    failureCallback: function() {
      console.log("Failed to get data! ");
    },

    checkResultSubmitForm: function(e) {
      if (this.dataResults.playerName) {
        this.submitUserResults();
        this.setGridComplete(false);
      }

      this.setError("");

      if (!this.dataResults.playerName) {
        this.setError("Name is required");
      }
      e.preventDefault();

    },

    // showAnswer: function() {
    //   setTimeout(() => {
    //     this.reCalc(); /*
    //                       Temporarily disabled
    //                       BUG: this will run into recursion if it exceeds the score limit
    //                       it is also updating (deleting ) from numberStore
    //                     */
    //   }, this.delaySpeed);
    // } //Slow reCalc

    randomInt1: function() {
      let int1 = this.generateRandomInt(this.dataRange) + 1;
      return (this.dataRandomInt1 = int1);
    },

    randomInt2: function() {
      let int2 = this.generateRandomInt(this.dataRange) + 1;
      return (this.dataRandomInt2 = int2);
    },
    //  multiplyMachineAnswer: function() {
    //   // console.clear(); //DEBUGGING: clear console for new messages
    //    console.log("multi machine answer");
    //   let machineCalcAnswer = this.randomInt1 * this.randomInt2;

    //   return (this.dataMachineCalcAnswer = machineCalcAnswer);
     
    // },

  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/styles/_variables.scss";
@import "../assets/styles/global.scss";

@mixin button {
  left: 0;
  top: 0px;
  box-shadow: none;
  border-radius: 0;
}

@mixin input {
  height: 36px;
  width: 135px;
  font: 16px/1em Helvetica, Arial, Sans-serif;
}

.app-multiply-container {
  position: relative;

  .app-block {
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0 auto;
    background: $dark-background-color;

    .app-panel {
      display: grid;
      grid-gap: 15px;
      // border: 2px solid #a6d7ff;
      color: $white-font-color;
      padding-top: 0px;
      margin-bottom: 0px;
      // width: 60%;
      max-width: 395px;
      margin: 0 auto;
      text-align: center;
      position: relative;
      z-index: 10;

      // background: $gradient2;
      // background-blend-mode: screen;
      // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      .ingame-message {
        margin: 0 auto 10px auto;
        width: 80%;
        text-align: center;

        &.ingame-message--complete {
        }

        &.ingame-message--thankyou {
        }
      }



      .meta-container {
        font-size: 0.9em;
        line-height: 1.2;
        margin-bottom: 10px;
        // background: goldenrod;
        display: flex;
        // justify-content: center;
        font-weight: bold;
        text-transform: uppercase;
        color: $white-font-color;

        .score-container {
          display: flex;
          position: absolute;
          top: 45px;
          left: 12%;
        }
        .level-container {
          display: flex;
          position: absolute;
          top: 45px;
          left: 70%;
        }
        .meta-title {
          padding-right: 5px;
          // display: flex;
          // align-items: center;
          // justify-content: center;
        }

        .score--negative-score {
          font-size: 90%;
          color: #f1522a;
        }

        .level-container {
        }
      }

      .start-button {
        // margin-top: 60px;
      }

      .quiz-container {
        // background: $dark-shaded;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.7em;
        line-height: 1;
        font-weight: bold;
        color: $white-font-color;

        .quiz-int-container {
          // margin-left: 35px;
          .quiz-content--int {
            // background: gold;
          }
        }

        button {
          @include button;
        }
        input {
          @include input;
          margin-left: 1px;
          padding-left: 10px;
        }
      }

      .result-container {
        // height: 25px;
        position: absolute;
        top: 50px;
        left: 33%;
        width: 35%;

        // text-align: center;
        // margin: 0 auto;
        overflow: hidden;
      }

      .ingame-quiz-message-container {
        height: 20px;
      }

      .grid-complete-container {
        button {
          @include button;
        }
        input {
          @include input;
          margin-left: 1px;
          padding-left: 8px;
        }
      }

      .character-container {
        display: none;
        position: absolute;
        z-index: 20;
        height: 156px;
        width: 110px;
        top: 0;
        width: 35%;
        right: 0px;

        .character-title {
          text-transform: capitalize;
          font-weight: bold;
          margin: 5px auto;
          position: absolute;
          bottom: 0;
          /* left: 25%; */
          /* text-align: center; */
          width: 100%;
        }

        .character-image {
          height: 173px;
          width: 173px;
          position: absolute;
          left: 33%;
        }
      }
    }
  }
}



</style>

const numberStore =  {
  11: [1, 1],
  12: [1, 2],
  13: [1, 3],
  14: [1, 4],
  15: [1, 5],
  16: [1, 6],
  17: [1, 7],
  18: [1, 8],
  19: [1, 9],
  110: [1, 10],

  21: [2, 1],
  22: [2, 2],
  23: [2, 3],
  24: [2, 4],
  25: [2, 5],
  26: [2, 6],
  27: [2, 7],
  28: [2, 8],
  29: [2, 9],
  210: [2, 10],

  31: [3, 1],
  32: [3, 2],
  33: [3, 3],
  34: [3, 4],
  35: [3, 5],
  36: [3, 6],
  37: [3, 7],
  38: [3, 8],
  39: [3, 9],
  310: [3, 10],

  41: [4, 1],
  42: [4, 2],
  43: [4, 3],
  44: [4, 4],
  45: [4, 5],
  46: [4, 6],
  47: [4, 7],
  48: [4, 8],
  49: [4, 9],
  410: [4, 10],

  51: [5, 1],
  52: [5, 2],
  53: [5, 3],
  54: [5, 4],
  55: [5, 5],
  56: [5, 6],
  57: [5, 7],
  58: [5, 8],
  59: [5, 9],
  510: [5, 10],

  61: [6, 1],
  62: [6, 2],
  63: [6, 3],
  64: [6, 4],
  65: [6, 5],
  66: [6, 6],
  67: [6, 7],
  68: [6, 8],
  69: [6, 9],
  610: [6, 10],

  71: [7, 1],
  72: [7, 2],
  73: [7, 3],
  74: [7, 4],
  75: [7, 5],
  76: [7, 6],
  77: [7, 7],
  78: [7, 8],
  79: [7, 9],
  710: [7, 10],

  81: [8, 1],
  82: [8, 2],
  83: [8, 3],
  84: [8, 4],
  85: [8, 5],
  86: [8, 6],
  87: [8, 7],
  88: [8, 8],
  89: [8, 9],
  810: [8, 10],

  91: [9, 1],
  92: [9, 2],
  93: [9, 3],
  94: [9, 4],
  95: [9, 5],
  96: [9, 6],
  97: [9, 7],
  98: [9, 8],
  99: [9, 9],
  910: [9, 10],

  101: [10, 1],
  102: [10, 2],
  103: [10, 3],
  104: [10, 4],
  105: [10, 5],
  106: [10, 6],
  107: [10, 7],
  108: [10, 8],
  109: [10, 9],
  1010: [10, 10]
}

export default numberStore

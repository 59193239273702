<template>
  <footer>
    <p>
      Players with the fastest time and least errors will appear on Top 10 leaderboard <br>
     
    </p>
    <p>
      Leaderboard is shown once the grid is complete 
    </p>
<a href="https://twitter.com/_speedytables" target="_blank" rel="noopener noreferrer">@_speedytables</a>
  </footer>
</template>

<script>
export default {
  name: "appFooter",
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  margin: 20px;

}
footer p {
    /* width: 60%; */
    margin: 5px auto;
    font-family: monospace;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex'; 
import numberStoreData from "./numberStore"
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        dataInGameMsg: "",
        userLevelData: "0",
        progressBar: false, 
        userScore: 0,
        timerStart: false,
        startButton: true,
        quizPanel: false,
        gridComplete: false,
        timeOn: true, 
        time: { h: 0, m: 0, s: 0, ms: 0 },
        dataResults: {
            playerName: null,
            score: null,
            wrongAnswers: null,
            character: null,
            time: null,
            level: null
        },
        dataLeaderBoardResults: [],
        errors: [],
        numberStore: numberStoreData,
        numberStoreUpdate: 0
    },
    mutations: {
        //syncronus 
        setCurrentInGameMsg(state, payload) {
            state.dataInGameMsg = payload;
        },
        setUserLevelData(state, payload) {
            state.userLevelData = payload
        },
        setUserScore(state, payload) {
            state.userScore = payload 
        },
        setTimerStart(state, value) {
            state.timerStart = value
        },
        setStartButton(state, value) {
            state.startButton = value
        },
        setQuizPanel(state, value) {
            state.quizPanel = value
        },
        setGridComplete(state, value) {
            state.gridComplete = value
        },
        setTimeOn(state, value) {
            state.timeOn = value
        },
        setTimer(state){

            if (state.timeOn) {
                if (++state.time.ms > 9) {
                    state.time.ms = 0;
                    state.time.s++;
                }
    
                if (state.time.s > 59) {
                    state.time.s = 0;
                    state.time.m++;
                }
    
                if (state.time.m > 59) {
                    state.time.m = 0;
                    state.time.h++;
                }
            }
        },
        setLeaderBoardResults(state, value) {
            state.dataLeaderBoardResults.push(value)
        },
        setError(state, value) {
            state.errors.push(value)
        },
        setNumberStore(state, value) {
            delete state.numberStore[value]    
        },
        setNumberStoreUpdate(state, value){
            state.numberStoreUpdate = value
            console.log("numberStoreUpdate >>>", state.numberStoreUpdate)
        },
        setProgressBar(state, value){
            state.progressBar = value; 
        }   

    }, 
    actions: {

        setCurrentInGameMsg(state, payload) {
            state.commit("setCurrentInGameMsg", payload);
        },
        setTimerStart(context, value) {
            context.commit('setTimerStart', value)
        },
        setStartButton(context, value){
            context.commit('setStartButton', value)
        },
        setQuizPanel(context, value) {
            context.commit('setQuizPanel', value)
        },
        setGridComplete(context, value) {
            context.commit('setGridComplete', value)
        },
        setTimeOn(context, value) {
            context.commit('setTimeOn', value)
        }, 
        setUserScore(context, value) {
            context.commit('setUserScore', value)
        },
        setProgressBar(context, value){
            context.commit('setProgressBar', value)
        }
 
    }, 
    modules: {}, 
    getters:{
        getCurrentInGameMsg: state => state.dataInGameMsg,
        getUserLevelData: state => state.userLevelData,
        getUserScore: state => state.userScore,
        getTimerH: state => state.time.h,
        getTimerM: state => state.time.m,
        getTimeS: state => state.time.s,
        getTimerMS: state => state.time.ms, 

    }
}); 
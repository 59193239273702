<template>
  <div class="leader-board-container" >
        <div class="leader-board__table" id="results">
          <div class="leader-board__theader">
            <div class="leader-board__table_header">rank</div>
            <div class="leader-board__table_header">player name</div>
            <div class="leader-board__table_header">score</div>
            <div class="leader-board__table_header">level</div>
            <div class="leader-board__table_header">time</div>
          </div>

          <div
            class="leader-board__table_row"
            v-for="item in $store.state.dataLeaderBoardResults"
            :key="item.id"
          >
            <div class="leader-board__table_small">
              <div class="leader-board__table_cell">rank</div>
              <div class="leader-board__table_cell rank-number"></div>
            </div>
            <div class="leader-board__table_small">
              <div class="leader-board__table_cell">player name</div>
              <div class="leader-board__table_cell">{{ item.playerName }}</div>
            </div>
            <div class="leader-board__table_small">
              <div class="leader-board__table_cell">score</div>
              <div class="leader-board__table_cell">{{ item.score }}</div>
            </div>
            <div class="leader-board__table_small">
              <div class="leader-board__table_cell">player level</div>
              <div class="leader-board__table_cell">{{ item.level }}</div>
            </div>
            <div class="leader-board__table_small">
              <div class="leader-board__table_cell">time</div>
              <div
                class="leader-board__table_cell"
              >{{ item.time.h }}:{{ item.time.m }}:{{ item.time.s }}:{{ item.time.ms }}</div>
            </div>
          </div>

        </div>
 </div>
</template>

<script>

    // TODO: use logic in this component 

    import db from "../store/firebase";
    import { mapMutations } from 'vuex'; 

    

    export default {
        name: "appLeaderBoard", 

        methods: {

                ...mapMutations(['setLeaderBoardResults']),

                leaderBoard: function() {
                alert();

                console.log("LeaderBoard function from component!!");
                let leaderBoardRef = db.collection("results");
                leaderBoardRef
                // .where("character", "==", "pikachu")
                .orderBy("score", "desc")
                .orderBy("time")
                .limit(10)
                .get()
                .then(snapshot => {
                snapshot.forEach(doc => {
                    console.log(doc.data());
                    this.setLeaderBoardResults(doc.data());
                });
                })
                // .then(this.leaderBoardParse(), this.failureCallback())
                .catch(err => {
                console.log("Error getting documents", err);
                });

              // stuff.docs.map(doc => doc.data());
            },
        }
    }

</script>
<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";

      .leader-board-container {
        text-transform: uppercase;

        .rank-number::before {
          counter-increment: section; /* Increment the value of section counter by 1 */
          content: counter(section); /* Display the value of section counter */
        }

        .leader-board__table {
          display: table;
          text-align: center;
          width: 90%;
          margin: 0 auto;
          border-collapse: separate;
          font-weight: 400;
        }

        .leader-board__table_row,
        .leader-board__theader {
          display: table-row;
        }

        .leader-board__table_header {
          display: table-cell;
          background: $gradient5;
          padding-top: 10px;
          padding-bottom: 10px;
          font-weight: 700;
          &:first-child {
            border-left: #ccc 1px solid;
            border-top-left-radius: 5px;
          }
          &:last-child {
            border-right: #ccc 1px solid;
            border-top-right-radius: 5px;
          }
        }

        .leader-board__table_small {
          display: table-cell;
        }

        .leader-board__table_row {
          > .leader-board__table_small {
            > .leader-board__table_cell {
              &:nth-child(odd) {
                display: none;
                padding-top: 10px;
                padding-bottom: 10px;
              }
              padding-top: 3px;
              padding-bottom: 3px;
              border-bottom: #7d97b9 2px solid;
            }
            &:first-child > .leader-board__table_cell {
              border-left: #7d97b9 1px solid;
            }
            &:last-child > .leader-board__table_cell {
              border-right: #7d97b9 1px solid;
            }
          }
          &:last-child > .leader-board__table_small {
            &:last-child > .leader-board__table_cell:last-child {
              border-bottom-right-radius: 5px;
            }
            &:first-child > .leader-board__table_cell:last-child {
              border-bottom-left-radius: 5px;
            }
          }
          &:nth-child(2n + 3) {
            background: $dark-shaded;
          }
        }

        @media screen and (max-width: 900px) {
          .leader-board__table {
            width: 90%;
          }
        }

        @media screen and (max-width: 650px) {
          .leader-board__table {
            display: block;
            font-size: 14px;
          }
          .leader-board__table_row:nth-child(2n + 3) {
            background: none;
          }
          .leader-board__theader {
            display: none;
          }
          .leader-board__table_row
            > .leader-board__table_small
            > .leader-board__table_cell:nth-child(odd),
          .table_cell {
            background: cornflowerblue;
            display: table-cell;
            width: 50%;
          }

          .leader-board__table_row
            > .leader-board__table_small
            > .leader-board__table_cell:nth-child(even),
          .table_cell {
            // background: #148891;
          }

          .leader-board__table_row {
          }

          .leader-board__table_row {
            > .leader-board__table_small {
              > .leader-board__table_cell {
                &:nth-child(odd) {
                  padding: 5px 0;
                }
              }
            }
          }

          .leader-board__table_row
            > .leader-board__table_small
            > .leader-board__table_cell {
            border: none;
          }

          .leader-board__table_row {
            display: table;
            width: 100%;
            border-collapse: separate;
            padding-bottom: 0;
            margin: 5% auto 0;
            text-align: center;
          }
          .leader-board__table_small {
            display: table-row;
          }
          .leader-board__table_row > .leader-board__table_small {
            &:first-child {
              > .leader-board__table_cell:last-child {
                border: none;
              }
              > .leader-board__table_cell {
                &:first-child {
                  // border-top: #ccc 1px solid;
                }
                &:last-child {
                  border-right: none;
                  // border-top: #ccc 1px solid;
                }
              }
            }
            > .leader-board__table_cell:first-child {
              // border-left: #ccc 1px solid;
            }
            &:last-child {
              > .leader-board__table_cell:first-child {
                border-right: none;
              }
              > .leader-board__table_cell {
                &:first-child {
                  border: none;
                }
                &:last-child {
                  border: none;
                }
              }
            }
            > .leader-board__table_cell:last-child {
              // border-right: #ccc 1px solid;
            }
          }
        }
      }
</style>